import React, { useState, useEffect } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { tr } from "react-date-range/dist/locale";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  TrashIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { API_IP, API_PORT } from "./AppConfig";
import "./style.css";

import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  IconButton,
  Popover,
  PopoverHandler,
  PopoverContent,
  Input,
  Dialog,
  Spinner,
  Select,
  Option,
} from "@material-tailwind/react";
const TABLE_HEAD = [
  "SIRA",
  "KART SAHİBİ",
  "KART NUMARASI",
  "ÜCRET",
  "AÇIKLAMA",
  "DURUM",
  "TARİH",
];
function formatDateToTurkishLocale(dateTimeString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  // İlgili tarih/saat dizesini JavaScript Date nesnesine çevirin
  const date = new Date(dateTimeString);

  // Türkçe tarih formatına çevirin
  return date.toLocaleDateString("tr-TR", options);
}

export default function PaymentLog() {
  const [selectedRange, setSelectedRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [modalState, setModalState] = useState(false); // Şu anki sayfa numarası
  const [active, setActive] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(false);
  const [successState, setSuccessState] = useState(null);

  const handleModalOpen = () => setModalState(!modalState);

  const handleSelect = (ranges) => {
    if (ranges && ranges[0] && ranges[0].startDate && ranges[0].endDate) {
      let endDate = new Date(ranges[0].endDate);
      let startDate = new Date(ranges[0].startDate);

      if (startDate.toISOString() === endDate.toISOString()) {
        endDate.setDate(endDate.getDate() + 1);
      }
      setSelectedRange({
        startDate,
        endDate,
        key: "selection",
      });
    } else {
      console.log("Tarih bilgileri eksik veya hatalı.");
    }
  };

  const renderPageNumbers = () => {
    if (totalElements <= itemsPerPage) {
      return null; // Yeterli sayfa yoksa herhangi bir sayfa numarası oluşturulmasın
    }

    const pageNumbers = [];
    const minPage = Math.max(1, active - 1); // Min sayfa numarası 1 olarak güncellendi
    const maxPage = Math.min(totalPages, active + 1);

    if (minPage > 1) {
      pageNumbers.push(
        <IconButton
          key={1}
          onClick={() => {
            setActive(1);
            loadPageData();
          }}
          className={`bg-[#EEEEEE]/100 font-[Inter] text-black hover:bg-blue-500 hover:text-white ${
            active === 1 ? "bg-blue-500 text-white shadow-xl" : ""
          }`}
        >
          1
        </IconButton>
      );
      if (minPage > 2) {
        pageNumbers.push(
          <IconButton
            key="ellipsis-start"
            disabled={true}
            className="bg-[#EEEEEE]/100 font-[Inter]  text-black hover:bg-blue-500 hover:text-white"
          >
            ...
          </IconButton>
        );
      }
    }

    for (let i = minPage; i <= maxPage; i++) {
      if (i >= 1 && i <= totalPages) {
        // Min sayfa numarası 1 olarak güncellendi
        pageNumbers.push(
          <IconButton
            key={i}
            onClick={() => {
              setActive(i);
              loadPageData();
            }}
            className={`bg-[#EEEEEE]/100 font-[Inter]  text-black hover-bg-blue-500 hover-text-white ${
              active === i ? "bg-blue-500 text-white shadow-xl" : ""
            }`}
          >
            {i}
          </IconButton>
        );
      }
    }

    if (maxPage < totalPages) {
      if (maxPage < totalPages - 1) {
        pageNumbers.push(
          <IconButton
            key="ellipsis-end"
            disabled={true}
            className="bg-[#EEEEEE]/100 text-black hover-bg-blue-500 hover-text-white"
          >
            ...
          </IconButton>
        );
      }
      pageNumbers.push(
        <IconButton
          key={totalPages}
          onClick={() => {
            setActive(totalPages);
            loadPageData();
          }}
          className={`bg-[#EEEEEE]/100 text-black hover-bg-blue-500 hover-text-white ${
            active === totalPages ? "bg-blue-500 text-white shadow-xl" : ""
          }`}
        >
          {totalPages}
        </IconButton>
      );
    }

    return pageNumbers;
  };

  const fetchData = () => {
    setLoadingData(true);
    let apiUrl = "";

    // selectedRange'den startDate ve endDate'i al
    const startDate = selectedRange.startDate;
    const endDate = selectedRange.endDate;

    if (searchText !== null && searchText !== "") {
      const searchTextUPPER = searchText.toUpperCase();

      // Tarihleri kontrol edip boşsa boş dize olarak ayarla
      const startTimeParam = startDate
        ? encodeURIComponent(startDate.toISOString())
        : "";
      const endTimeParam = endDate
        ? encodeURIComponent(endDate.toISOString())
        : "";

      apiUrl = `https://${API_IP}:${API_PORT}/PaymentRequest/findByLikeIgnoreCase?param=${searchTextUPPER}&page=${
        active - 1
      }&startTime=${startTimeParam}&endTime=${endTimeParam}&size=${itemsPerPage}&userid=${
        sessionStorage.getItem("userType") === "admin"
          ? sessionStorage.getItem("userType")
          : sessionStorage.getItem("userId")
      }`;
    } else {
      // Tarihleri kontrol edip boşsa boş dize olarak ayarla
      const startTimeParam = startDate
        ? encodeURIComponent(startDate.toISOString())
        : "";
      const endTimeParam = endDate
        ? encodeURIComponent(endDate.toISOString())
        : "";

      apiUrl = `https://${API_IP}:${API_PORT}/PaymentRequest/findByLikeIgnoreCaseResults?page=${
        active - 1
      }&size=${itemsPerPage}&startTime=${startTimeParam}&endTime=${endTimeParam}&userid=${
        sessionStorage.getItem("userType") === "admin"
          ? sessionStorage.getItem("userType")
          : sessionStorage.getItem("userId")
      }`;
    }

    apiUrl += `&succeeded=${successState === null ? "" : successState}`;

    return fetch(apiUrl).then((response) => {
      if (!response.ok) {
        throw Error("Network response was not ok");
      }
      return response.json();
    });
  };

  const loadPageData = () => {
    return fetchData()
      .then((data) => {
        setData(data.content);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    loadPageData();
  }, [active, successState, searchText, itemsPerPage]);

  const handleSucceeded = (value) => {
    setLoadingData(true);
    setSuccessState(value);
    setActive(1);
    loadPageData();
  };
  const handleSearch = (value) => {
    setLoadingData(true);
    setSearchText(value);
    loadPageData();
    setActive(1);
  };
  const handleClear = () => {
    setSelectedRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
    setSearchText("");
    handleSucceeded(null);
    setActive(1);
  };

  const prev = () => {
    if (active > 0) {
      setActive(active - 1);
      loadPageData();
    }
  };

  const next = () => {
    if (active < totalPages - 1) {
      setActive(active + 1); // Increment active to go to the next page
      loadPageData();
    }
  };
  const changeItemsPerPage = (e) => {
    setItemsPerPage(e);
    setActive(1);
  };

  return (
    <div
      className={`flex w-full h-full p-2 flex-col gap-2 justify-start bg-blue-50 rounded-md`}
    >
      <div className="flex max-h-1/12 bg-white flex-col justify-around w-full py-2 px-1 rounded-xl shadow-xl">
        <div className="flex w-full gap-2">
          <div className="w-2/3 flex p-2 flex-row gap-2">
            <Input
              label="Ara"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            />
          </div>
          <div className="flex w-1/3 items-center gap-2">
            <Button
              variant={successState ? "filled" : "text"}
              className="font-[Inter]"
              color="green"
              onClick={() => handleSucceeded(true)}
            >
              Başarılı
            </Button>
            <Button
              variant={successState === false ? "filled" : "text"}
              className="font-[Inter]"
              color="red"
              onClick={() => handleSucceeded(false)}
            >
              Başarısız
            </Button>
            <Button
              variant={successState === null ? "filled" : "text"}
              className="font-[Inter]"
              color="blue"
              onClick={() => handleSucceeded(null)}
            >
              Tümü
            </Button>
            <Button
              onClick={handleModalOpen}
              className="font-[Inter] flex gap-2 px-4 justify-start items-center"
              variant="text"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
              >
                <path
                  d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H17C17.2761 0.5 17.5 0.723858 17.5 1C17.5 1.27614 17.2761 1.5 17 1.5H1C0.723858 1.5 0.5 1.27614 0.5 1Z"
                  fill="white"
                  stroke="#707070"
                />
                <path
                  d="M2.5 6C2.5 5.72386 2.72386 5.5 3 5.5H15C15.2761 5.5 15.5 5.72386 15.5 6C15.5 6.27614 15.2761 6.5 15 6.5H3C2.72386 6.5 2.5 6.27614 2.5 6Z"
                  fill="white"
                  stroke="#707070"
                />
                <path
                  d="M5.5 11C5.5 10.7239 5.72386 10.5 6 10.5H12C12.2761 10.5 12.5 10.7239 12.5 11C12.5 11.2761 12.2761 11.5 12 11.5H6C5.72386 11.5 5.5 11.2761 5.5 11Z"
                  fill="white"
                  stroke="#707070"
                />
              </svg>
              Tarih Filtrele
            </Button>
            <Button
              onClick={handleClear}
              color="orange"
              className="font-[Inter] flex gap-2 items-center text-orange-500"
              variant="text"
            >
              <TrashIcon className="h-5 w-5 text-orange-500" />
              Temizle
            </Button>
          </div>
        </div>

        <Dialog
          size="md"
          open={modalState}
          handler={handleModalOpen}
          className="bg-transparent w-1/4 shadow-none"
        >
          <Card className="bg-blue-50 w-full max-w-[32rem]">
            <CardBody className="flex flex-col relative">
              <div className="absolute top-2 right-2">
                <IconButton
                  onClick={handleModalOpen}
                  className="bg-white rounded-full text-red hover:bg-red-600 hover:text-white"
                  variant="text"
                >
                  <XMarkIcon className="h-6 w-6" />
                </IconButton>
              </div>
              <div className="w-full flex flex-col gap-2 justify-center items-center">
                <div className="flex bg-white rounded-xl pt-2 px-2 justify-center items-center">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => handleSelect([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={[selectedRange]}
                    locale={tr}
                  />
                </div>
                <div className="w-full items-center justify-center flex"></div>
              </div>
            </CardBody>

            <CardFooter className="pt-0 px-20">
              <Button
                onClick={() => {
                  loadPageData();
                  handleModalOpen();
                  setActive(1);
                }}
                className="w-full "
                color="blue"
                variant="gradient"
              >
                Filtrele
              </Button>
            </CardFooter>
          </Card>
        </Dialog>
      </div>

      {loadingData ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Spinner color="indigo" className="h-16 w-16 text-gray-900/50" />
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-xl py-4">
          <div className="max-h-full  overflow-y-auto my-2">
            <CardBody className="overflow-auto">
              <table className="w-full min-w-max table-auto text-left">
                <thead className="w-full">
                  <tr className="w-full bg-blue-gray-50/50">
                    {TABLE_HEAD.map((head) => (
                      <th key={head} className=" p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-[Inter] font-bold text-sm leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="font-[Inter] w-full">
                  {data.map(
                    (
                      {
                        cardHoldersName,
                        cardNo,
                        currencyAmount,
                        orderDescription,
                        createdAt,
                        userId,
                        resultCode,
                        resultDescription,
                      },
                      index
                    ) => {
                      const isLast = index === data.length - 1;
                      const classes = isLast
                        ? "p-4 border-b border-blue-gray-50"
                        : "p-4 border-b border-blue-gray-50";
                      let modifedOrderDesc = orderDescription.replace(
                        "\\n",
                        " "
                      );
                      modifedOrderDesc = modifedOrderDesc.replace("\\n", " ");
                      return (
                        <tr key={index}>
                          <td className={classes}>
                            <Chip
                              className="font-[Inter] rounded-full w-6 h-6 flex items-center justify-center bg-blue-gray-50 text-black"
                              variant="ghost"
                              value={
                                active === 1
                                  ? index + 1
                                  : itemsPerPage * (active - 1) + index + 1
                              }
                            />
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="paragraph"
                              color="blue-gray"
                              className="font-normal font-[Inter]"
                            >
                              {cardHoldersName}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="paragraph"
                              color="blue-gray"
                              className="font-normal font-[Inter]"
                            >
                              {cardNo}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Chip
                              className="font-[Inter]  w-20 text-sm"
                              variant="ghost"
                              value={`${currencyAmount} ₺`}
                            />
                          </td>
                          <td className={classes}>
                            <Popover>
                              <PopoverHandler>
                                <Button
                                  className="font-[Inter] "
                                  size="sm"
                                  color="amber"
                                >
                                  göster
                                </Button>
                              </PopoverHandler>
                              <PopoverContent>
                                {modifedOrderDesc}
                              </PopoverContent>
                            </Popover>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                className="font-[Inter] text-sm"
                                variant="ghost"
                                value={resultDescription}
                                color={resultCode === "0000" ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <Typography
                                className="font-[Inter]"
                                variant="paragraph"
                                color="blue-gray"
                              >
                                {formatDateToTurkishLocale(createdAt)}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </CardBody>
          </div>
          <div className="font-[Inter] flex flex-col">
            <div className="flex items-center justify-around">
              <Button
                variant="text"
                className="font-[Inter] flex items-center gap-2"
                onClick={prev}
                disabled={active === 1}
              >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> ÖNCEKİ
              </Button>
              <div className="flex items-center gap-2">
                {renderPageNumbers()}
              </div>
              <Button
                variant="text"
                className="font-[Inter] flex items-center gap-2"
                onClick={next}
                disabled={active === totalPages}
              >
                SONRAKİ <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </Button>
              <div className="w-72">
                <Select
                  value={itemsPerPage}
                  label="Gösterim Sayısı"
                  onChange={(e) => changeItemsPerPage(e)}
                >
                  <Option value="10">10</Option>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                  <Option value="200">200</Option>
                  <Option value="500">500</Option>
                  <Option value="1000">1000</Option>
                </Select>
              </div>
            </div>
            <div className="text-center justify-center items-center flex text-xs text-gray-500 pt-2">
              Toplam
              <span className="font-bold px-1 text-black/2">
                {totalElements}
              </span>
              geçmiş işlem
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
