import React, { useState, useEffect } from "react";
import { LoginScreen } from "./components/LoginScreen";
import Home from "./components/Home";
function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserID] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);

  // Sayfa yüklendiğinde sessionStorage'den verileri al
  useEffect(() => {
    const storedLoggedIn = sessionStorage.getItem("loggedIn");
    if (storedLoggedIn) {
      setLoggedIn(JSON.parse(storedLoggedIn));
    }
  }, [loggedIn]);

  return (
    <div className="w-screen h-screen bg-blue-50">
      {loggedIn ? (
        <Home
          setLoggedIn={setLoggedIn}
          userFirstName={userFirstName}
          userLastName={userLastName}
          userId={userId}
          userType={userType}
          userName={userName}
        />
      ) : (
        <LoginScreen
          setLoggedIn={setLoggedIn}
          setUserID={setUserID}
          setUserType={setUserType}
          setUserName={setUserName}
          setUserFirstName={setUserFirstName}
          setUserLastName={setUserLastName}
        />
      )}
    </div>
  );
}

export default App;
