import React, { useState } from "react";
import Yerlem from "../images/yerlemicon.svg";
import { Textarea, Button, Typography } from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SmsPage({ userId }) {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [messageText, setMessageText] = useState("");
  const notify = (message, state) => {
    state
      ? toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      : toast.warn(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
  };

  const handlePhoneChange = (e) => {
    const digitsOnly = e.target.value.replace(/\D/g, "");

    const groupedNumbers = digitsOnly.match(/.{1,10}/g) || [];
    setPhoneNumbers(groupedNumbers);
  };
  const handleSendSmsOnClick = async () => {
    if (messageText !== "" && messageText !== null) {
      try {
        for (const phoneNumber of phoneNumbers) {
          const response = await fetch(
            `http://78.186.223.18:7077/singleSms/send`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                phone: phoneNumber,
                message: messageText.replace(/\n/g, "\\n"),
              }),
            }
          );

          const data = await response.json();

          if (data.response) {
            notify(`Mesaj başarıyla gönderildi: ${phoneNumber}`, true);
          } else {
            notify(`Hata: Mesaj gönderilemedi : ${phoneNumber}`, false);
          }
        }
      } catch (error) {
        console.error(`Hata: ${error.message}`);
      }
    } else {
      notify("Mesaj Metni Boş Olamaz", false);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center  w-full h-full  ">
      <img
        alt="yerlemBack"
        src={Yerlem}
        className="absolute h-full object-cover z-0 "
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="font-[Inter] relative w-[75%] flex flex-col gap-4">
        <div>
          <Textarea
            className="bg-white font-[Inter] "
            value={phoneNumbers.join(",")}
            label="Sms göndermek istediğiniz numaraları giriniz"
            onChange={handlePhoneChange}
          />
          <div className="w-full justify-end flex">
            <Typography
              variant="paragraph"
              className="text-sm font-sans text-black/30  "
            >
              Telefon numaralarını 555******* formatında yazınız.
            </Typography>
          </div>
        </div>
        <Textarea
          className="bg-white font-[Inter] "
          label="Sms olarak göndermek istediğiniz metni giriniz"
          rows={12}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
        <div className="flex w-full justify-end py-1.5">
          <Button
            onClick={handleSendSmsOnClick}
            size="md"
            color="blue"
            className="font-[Inter] rounded-md"
          >
            Gönder
          </Button>
        </div>
      </div>
    </div>
  );
}
