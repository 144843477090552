import React, { useState, useRef } from "react";
import {
  Card,
  Typography,
  Button,
  Progress,
  Spinner,
  IconButton,
  Tooltip,
  Input,
  Alert,
} from "@material-tailwind/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import {
  UserCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import * as XLSX from "xlsx";
import { API_IP, API_PORT } from "./AppConfig";
export default function MultiplePaymentPage() {
  const [uploadProgress, setUploadProgress] = useState(null);
  const [paymentQueue, setPaymentQueue] = useState(0);
  const [excelError, setExcelError] = useState(null);
  const [data, setData] = useState([]);
  const schoolName = "Nesibe Aydın Okulları";
  const fileInputRef = useRef(null);
  const userId = sessionStorage.getItem("userId");

  const handlePayment = async () => {
    try {
      for (let i = 0; i < data.length; i++) {
        const cardNo = data[i].cardNo.replace(/\s+/g, "");
        const postData = {
          userIdFk: userId,
          cardNo: cardNo,
          cardExpire: data[i].expireDate,
          cardHoldersName: data[i].name,
          phoneNumber: data[i].phoneNo,
          cardCvv: data[i].cvv,
          currencyAmount: parseFloat(data[i].amount),
          orderDescription:
            "Sayın velimiz\\n" +
            schoolName +
            " okulumuzun servis firması olarak\\n",
          clientIp: "85.96.248.42",
        };

        // API'ye POST isteği gönderme
        const response = await fetch(
          `https://${API_IP}:${API_PORT}/PaymentRequest/savePaymentRequest`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );

        // Yanıtın metin içeriğini okuyun
        const responseText = await response.text();

        const keyValuePairs = responseText.split(",").map((item) => {
          const [key, value] = item.split(":");
          return { [key.trim()]: value.trim() };
        });

        const jsonObject = Object.assign({}, ...keyValuePairs);
        console.log(jsonObject.resultCode, jsonObject.resultDetail);

        // Dönen sonuçları data dizisine ekleyin
        data[i].responseCode = jsonObject.resultCode;
        data[i].responseDetail = jsonObject.resultDetail;
        setPaymentQueue(i + 1);
        console.log(i + 1);
      }
      const toExcelData = data.map((item) => {
        return {
          "İsim Soyisim": item.name,
          Tutar: item.amount,
          "Telefon Numarası": item.phoneNo,
          "Cevap Kodu": item.responseCode,
          "Cevap Detayı": item.responseDetail,
        };
      });

      handleDownload(toExcelData);
    } catch (error) {
      console.error("POST isteği sırasında bir hata oluştu:", error);
    }
  };
  const handleDownload = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "Rapor.xlsx");
  };
  const TABLE_HEAD = [
    "SIRA",
    "İSİM SOYİSİM",
    "TUTAR",
    "SON KULLANIM TARİHİ",
    "KART NUMARASI",
    "KALDIR",
  ];

  const handleFileUpload = () => {
    try {
      fileInputRef.current.click(); // Dosya seçme inputunu tetikler
      setPaymentQueue(0);
    } catch (error) {
      console.error("Dosya yükleme sırasında bir hata oluştu:", error);
    }
  };

  const handleFileChange = (e) => {
    try {
      setData([]);
      setUploadProgress(false);

      const file = e.target.files[0];
      if (file && file.name.endsWith(".xlsx")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const fileContent = e.target.result;
          const workbook = XLSX.read(fileContent, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const range = XLSX.utils.decode_range(sheet["!ref"]);
          const totalRows = range.e.r;

          for (let rowIndex = 1; rowIndex <= totalRows; rowIndex++) {
            const cardNoCell =
              sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 0 })];
            const expireDateCell =
              sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 1 })];
            const cvvCell =
              sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 1 })];

            const nameCell =
              sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 2 })];
            const amountCell =
              sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 3 })];
            const phoneCell =
              sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 4 })];

            if (
              cardNoCell &&
              expireDateCell &&
              nameCell &&
              cvvCell &&
              amountCell &&
              phoneCell
            ) {
              const expireDateCellFormat = expireDateCell.v;
              const cvvCellFormat = cvvCell.v;
              // expireDate'i düzenle
              const expireDateParts = expireDateCellFormat.split("-");
              const formattedExpireDate = `20${expireDateParts[1]}${expireDateParts[0]}`;
              const formattedPhone = phoneCell.v
                .replace(/\s/g, "")
                .replace(/^0+/, "");

              // cvv'yi düzenle
              const cvvParts = cvvCellFormat.split("-");
              const lastPart = cvvParts[cvvParts.length - 1];

              const cardNo = cardNoCell.v.replace(/-/g, "");
              const expireDate = formattedExpireDate;
              const name = nameCell.v;
              const cvv = lastPart;
              const amount = amountCell.v;
              const phoneNo = formattedPhone;

              console.log(cardNo, expireDate, name, cvv, amount, phoneNo);
              const processedData = {
                cardNo,
                expireDate,
                name,
                cvv,
                amount,
                phoneNo,
                data,
              };
              console.log(rowIndex, processedData);
              setExcelError(false);
              setData((prevData) => [...prevData, processedData]);
            } else {
              setExcelError(true);
            }
          }
        };

        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            setUploadProgress(true);
          }
        };

        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.error("Dosya yükleme sırasında bir hata oluştu:", error);
    }
  };

  const handleDeleteRow = (indexToDelete) => {
    // indexToDelete ile veriyi silebiliriz
    const updatedData = [...data];
    updatedData.splice(indexToDelete, 1);
    setData(updatedData);
  };

  return (
    <div className="font-[Inter] flex w-full h-full flex-col gap-2 rounded-md px-2 pt-2 ">
      <div className="w-full h-1/12 bg-white rounded-xl my-2 py-2 flex flex-row items-center justify-around">
        <Button
          variant="text"
          className="font-[Inter] rounded-md h-full flex flex-row gap-2  items-center border text-black bg-orange-400 border-gray-900/5 shadow-md hover:text-orange-400 hover:bg-white"
          onClick={handleFileUpload}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>
          <input
            ref={fileInputRef}
            type="file"
            value=""
            accept=".xlsx"
            style={{ display: "none" }} // Dosya seçme inputunu gizler
            onChange={handleFileChange}
          />

          <Typography variant="h6">Dosya Yükle</Typography>
        </Button>
        {uploadProgress === false ? (
          <div className="w-1/3 flex flex-col gap-2 items-center">
            <Spinner color="indigo" />
          </div>
        ) : null}

        {paymentQueue === 0 ? (
          <Button
            variant="text"
            className="font-[Inter] rounded-md h-full flex flex-row gap-2 items-center border text-white bg-green-500 border-gray-900/5 shadow-md hover:text-green-500 hover:bg-white"
            onClick={handlePayment}
          >
            <Typography variant="h6">Ödeme Yap</Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
            </svg>
          </Button>
        ) : (
          <div className="w-1/4">
            <div className="mb-2 flex items-center justify-between gap-4">
              <Typography color="blue-gray" variant="h6">
                Tamamlandı
              </Typography>
              <Typography color="blue-gray" variant="h6">
                {`${paymentQueue} / ${data.length} Tamamlandı`}{" "}
              </Typography>
            </div>
            <Progress
              size="md"
              color="green"
              value={Math.round((paymentQueue * 100) / data.length)}
            />
          </div>
        )}
      </div>
      {uploadProgress === true ? (
        <Card className="h-11/12 w-full h-full">
          <div className="overflow-auto h-[88vh]">
            <table className="font-[Inter] w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className=" bg-blue-gray-50/50 py-2 px-4 text-black-500 font-bold text-sm uppercase"
                    >
                      <Typography
                        variant="small"
                        color="black"
                        className="font-bold font-[Inter]  opacity-70 "
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="p-4">
                {data.map(
                  (
                    { cvv, name, amount, cardNo, expireDate, phoneNo },
                    index
                  ) => {
                    const isLast = index === data.length - 1;
                    const classes = isLast
                      ? "p-4 "
                      : "p-4 border-b border-blue-gray-50 ";

                    return (
                      <tr key={index}>
                        <td className={classes}>
                          <div className="flex items-center pl-4 gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-[Inter] font-bold"
                            >
                              {index + 1}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center pl-4  gap-3">
                            <UserCircleIcon className="h-12 w-12 text-black-500" />
                            <div className="flex flex-col">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-[Inter] font-normal capitalize"
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-[Inter] font-normal opacity-70"
                              >
                                {phoneNo}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-[Inter] font-bold"
                          >
                            {amount} ₺
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-[Inter] font-normal"
                          >
                            {expireDate}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-[Inter] font-normal capitalize"
                              >
                                {cardNo}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-[Inter] font-normal opacity-70"
                              >
                                {cvv}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <Tooltip content="Kaldır">
                            <IconButton
                              className="bg-[#FF0000] font-[Inter]  text-white hover:bg-[#FAA0A0] hover:text-black"
                              variant="text"
                              onClick={() => handleDeleteRow(index)} // Tıklama işlemi için handleDeleteRow işlevini çağırın
                            >
                              <TrashIcon className="h-4 w-4" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </Card>
      ) : (
        <Alert
          color="red"
          open={excelError === true}
          className="font-[Inter] max-w-screen-md"
        >
          <Typography className="font-[Inter] " variant="h5" color="white">
            EXCEL HATASI
          </Typography>
          <Typography color="white" className="mt-2 font-[Inter]  font-normal">
            Yüklemek istediğiniz excel dosyasınızın formatı hatalıdır. Lütfen
            kontrol ediniz.
          </Typography>
        </Alert>
      )}
    </div>
  );
}
