import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
  Select,
  Option,
  Typography,
  Alert,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import {
  CreditCardIcon,
  PhoneIcon,
  NewspaperIcon,
} from "@heroicons/react/24/solid";
import Yerlem from "../images/yerlemicon.svg";
import Troy from "../images/troy.png";
import MasterCard from "../images/mastercard.png";
import Visa from "../images/visa.png";
import Secure from "../images/3dsecure.png";
import SmartChip from "../images/smartchip.svg";
import VisaLogo from "../images/visa.svg";
import { API_IP, API_PORT } from "./AppConfig";

function formatCardNumber(value) {
  const val = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = val.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || "";
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(" ");
  } else {
    return value;
  }
}
function formatPhoneNumber(value) {
  const val = value.replace(/\D/g, "").substring(0, 10); // Sadece rakamları ve maksimum 10 karakteri al
  const matches = val.match(/(\d{1,3})(\d{0,3})(\d{0,4})/);
  if (matches) {
    const formattedNumber = `(${matches[1]}) ${matches[2]} ${matches[3]}`;
    return formattedNumber.trim();
  }

  return val;
}
function IconFailed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#00000"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function IconSuccess() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default function SinglePaymentPage({ onButtonClick }) {
  const [cardNumber, setCardNumber] = React.useState("");
  const [cardCVV, setCardCVV] = React.useState("");
  const [cardExpiresYear, setCardExpiresYear] = React.useState("");
  const [cardExpiresMonth, setCardExpiresMonth] = React.useState("");
  const [cardHolder, setCardHolder] = React.useState("");
  const [currencyAmount, setCurrencyAmount] = React.useState(0);
  const [fractionAmount, setFractionAmount] = React.useState(0);
  const [errorAlert, setErrorAlert] = React.useState(true);
  const [responseText, setResponseText] = useState("");
  const [successPayment, setSuccessPayment] = useState(false);
  const [failedPayment, setFailedPayment] = useState(false);
  const [cardHoldersPhone, setCardHoldersPhone] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [schoolName, setSchoolName] = React.useState("");
  const [specialMessage, setSpecialMessage] = React.useState("");
  const [yesButtonState, setYesButtonState] = React.useState(true);
  const [yesButtonAmount, setYesButtonAmount] = React.useState(0);

  const schoolList = [
    { id: 1, name: "NESİBE AYDIN OKULLARI" },
    { id: 2, name: "AYŞE KEMAL İNANÇ İMAM HATİP ORTAOKULU" },
    { id: 3, name: "ASELSAN MESLEKİ VE TEKNİK ANADOLU LİSESİ" },
    { id: 4, name: "ŞEMS - İ TEBRİZİ KIZ ANADOLU İMAM HATİP LİSESİ" },
    { id: 5, name: "MAREŞAL MUSTAFA KEMAL ORTAOKULU" },
    { id: 6, name: "HAMİDİYE İMAM HATİP ORTAOKULU" },
    { id: 7, name: "MEHMET BEĞEN ORTAOKULU" },
    { id: 8, name: "ŞEHİT MUSTAFA ÇUHADAR ORTAOKULU" },
    { id: 9, name: "ŞEHİT OSMAN DEMİR İLKOKULU" },
    { id: 10, name: "OSMANGAZİ İLKOKULU" },
    { id: 11, name: "TOKİ İLKOKULU" },
    { id: 12, name: "HATIP ÖĞRETMEN BAHADIRTÜRK İÖO" },
    { id: 13, name: "MERAM BEYBES ŞEHİT ÖĞRETMEN HÜSEYİN YAVUZ İLKOKULU" },
    { id: 14, name: "ELMAS KUR'AN KURSU" },
    { id: 15, name: "YUNUS EMRE KURAN KURSLARI" },
    { id: 16, name: "DİĞER" },
  ];
  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
    !openDialog ? setYesButtonState(true) : setYesButtonState(false);
  };
  const handleChangeSchool = (e) => {
    setSchoolName(e);
  };

  const onClickSendButton = async () => {
    setYesButtonState(false);
    if (yesButtonAmount < 1) {
      console.log("girdii" + yesButtonAmount);

      if (
        cardNumber === "" ||
        cardCVV === "" ||
        cardExpiresYear === "" ||
        cardExpiresMonth === "" ||
        cardHolder === "" ||
        cardHoldersPhone === "" ||
        currencyAmount === 0 ||
        schoolName === ""
      ) {
        setErrorAlert(false);
      } else {
        try {
          // POST isteği için kullanılacak veriyi hazırlayın
          const cardNo = cardNumber.replace(/\s+/g, "");
          const phoneNo = cardHoldersPhone.replace(/\D/g, "");

          const postData = {
            userIdFk: sessionStorage.getItem("userId"),
            cardNo: cardNo,
            cardExpire: cardExpiresYear + cardExpiresMonth,
            cardHoldersName: cardHolder,
            phoneNumber: phoneNo,
            cardCvv: cardCVV,
            currencyAmount: parseFloat(currencyAmount + "." + fractionAmount),
            orderDescription:
              schoolName === "DİĞER"
                ? specialMessage
                : "Sayın velimiz\\n" +
                  schoolName +
                  " okulumuzun servis firması olarak\\n",
            clientIp: "85.96.248.42",
          };

          // API'ye POST isteği gönderme
          const response = await fetch(
            `https://${API_IP}:${API_PORT}/PaymentRequest/savePaymentRequest`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(postData),
            }
          );

          // Yanıtın metin içeriğini okuyun
          const responseText = await response.text();

          const keyValuePairs = responseText.split(",").map((item) => {
            const [key, value] = item.split(":");
            return { [key.trim()]: value.trim() }; // Boşlukları kaldırarak key ve value'ları elde ediyoruz
          });

          const jsonObject = Object.assign({}, ...keyValuePairs);

          if (jsonObject.resultCode === "0000") {
            setSuccessPayment(true);
            setErrorAlert(true);
            setFailedPayment(false);
          } else {
            setFailedPayment(true);
            setSuccessPayment(false);
            setErrorAlert(true);
            setResponseText(jsonObject.resultDetail);
          }
          onButtonClick("Geçmiş Ödeme İşlemleri");
        } catch (error) {
          console.error("POST isteği sırasında bir hata oluştu:", error);
          setErrorAlert(true); // Hata bildirimini göstermek için bir state'i güncelleyebilirsiniz
        }
      }
    }
    setYesButtonState(true);
    handleOpenDialog();
  };

  const handleYearSelect = (selectedYear) => {
    setCardExpiresYear(selectedYear);
  };

  const handleMonthSelect = (selectedMonth) => {
    setCardExpiresMonth(selectedMonth);
  };
  const optionsyear = [];
  const optionsmonth = [];

  // 2023'den 2050'ye kadar olan yılları döngü ile oluşturun
  for (let year = 2023; year <= 2050; year++) {
    optionsyear.push(
      <Option key={year} value={year.toString()}>
        {year}
      </Option>
    );
  }
  // 1'den 12'ye kadar olan ayları döngü ile oluşturun
  for (let month = 1; month <= 12; month++) {
    if (month < 10) {
      month = "0" + month;
    }
    optionsmonth.push(
      <Option key={month} value={month.toString()}>
        {month}
      </Option>
    );
  }

  return (
    <div className="font-[Inter] flex flex-col items-center justify-center  w-full h-full  ">
      <div className="h-[80%] flex-col flex items-center justify-center gap-4">
        <img
          alt="yerlemBack"
          src={Yerlem}
          className="font-[Inter] absolute h-full object-cover z-0 "
        />

        {!errorAlert ? (
          <Alert className="font-[Inter] " color="amber">
            Lütfen boş alanları doldurunuz.
          </Alert>
        ) : null}
        {successPayment ? (
          <Alert
            icon={<IconSuccess />}
            className="font-[Inter]  rounded-xl border-l-4 border-[#2ec946] bg-[#2ec946]/50 font-medium text-black"
          >
            Ödeme işlemi başarılı lütfen gerekli bilgileri kaydettikten sonra
            sayfayı yenileyiniz.
          </Alert>
        ) : null}
        {failedPayment ? (
          <Alert
            icon={<IconFailed />}
            className="font-[Inter] rounded-xl border-l-4 border-[#FF0000] bg-[#FF6666]/50 font-medium text-black"
          >
            {responseText}
          </Alert>
        ) : null}
        <Card className="font-[Inter] w-full max-w-[30rem] ">
          <CardHeader
            color="white"
            floated={false}
            shadow={false}
            className="grid place-items-center rounded-b-none py-4 text-center"
          >
            <Typography className="font-[Inter] ">
              Tutar : {currencyAmount + "," + fractionAmount} ₺
            </Typography>

            <div className="min-w-[300px] min-h-[200px] rounded-2xl bg-gradient-to-r from-[#25DEF2] to-[#4B8EDF] text-white flex flex-col justify-center items-center">
              <img
                alt="YerlemIcon"
                src={Yerlem}
                className="absolute h-full object-cover z-0 "
              />
              <div className="h-[60px] w-full flex flex-row justify-between ">
                <img src={SmartChip} alt="Logo 3" className="h-8 ml-4 mt-4" />
                <img src={VisaLogo} alt="Logo 3" className="h-4 mt-6 mr-4" />
              </div>
              <div className="h-[80px] flex items-center justify-center w-full">
                <Typography
                  variant="h4"
                  className="font-[Inter] font-light"
                  color="white"
                >
                  {cardNumber}
                </Typography>
              </div>
              <div className="h-[60px] w-full flex flex-row justify-between ">
                <div className="flex flex-col max-w-[170px] ml-6 justify-center ">
                  <Typography
                    className="font-[Inter] "
                    variant="small"
                    color="white"
                  >
                    {cardHolder}
                  </Typography>
                </div>
                <div className="flex flex-col max-w-[50px] mr-8 justify-center ">
                  <Typography
                    className="font-[Inter] "
                    variant="small"
                    color="white"
                  >
                    {cardExpiresYear}/{cardExpiresMonth}
                  </Typography>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <form className="flex flex-col gap-4">
              <div>
                <div className="flex flex-row gap-2">
                  <Input
                    maxLength={5}
                    value={currencyAmount === 0 ? null : currencyAmount}
                    onChange={(event) =>
                      event.target.value <= 20000
                        ? setCurrencyAmount(event.target.value)
                        : setCurrencyAmount(20000)
                    }
                    containerProps={{ className: "min-w-[60px]" }} // Artırılan değer
                    label="00 "
                  />
                  <Input
                    maxLength={2}
                    value={fractionAmount === 0 ? null : fractionAmount}
                    onChange={(event) => setFractionAmount(event.target.value)}
                    containerProps={{ className: "min-w-[60px]" }} // Artırılan değer
                    label=",00"
                  />

                  <Input
                    label="Kart Numarası"
                    maxLength={19}
                    value={formatCardNumber(cardNumber)}
                    containerProps={{ className: "min-w-[200px]" }} // Artırılan değer
                    onChange={(event) => setCardNumber(event.target.value)}
                    icon={
                      <CreditCardIcon className="h-5 w-5 text-blue-gray-300" />
                    }
                  />
                </div>

                <div className="mt-4 flex items-center justify-around gap-4">
                  <Typography
                    className="font-[Inter] "
                    variant="small"
                    color="gray"
                  >
                    Son Kullanım Tarihi
                  </Typography>
                  <Typography
                    className="font-[Inter] "
                    variant="small"
                    color="gray"
                  >
                    Güvenlik Kodu
                  </Typography>
                </div>
                <div className="mb-4 flex items-center justify-around gap-4">
                  <div className="w- flex flex-col">
                    <Select
                      className="font-[Inter] "
                      containerProps={{ className: "min-w-[92px]" }}
                      onChange={handleYearSelect}
                      label="Yıl"
                      animate={{
                        mount: { y: 0 },
                        unmount: { y: 25 },
                      }}
                    >
                      {optionsyear}
                    </Select>
                  </div>
                  <div className="w-36 flex flex-col">
                    <Select
                      className="font-[Inter] "
                      onChange={handleMonthSelect}
                      containerProps={{ className: "min-w-[72px]" }}
                      label="Ay"
                      animate={{
                        mount: { y: 0 },
                        unmount: { y: 25 },
                      }}
                    >
                      {optionsmonth}
                    </Select>
                  </div>
                  <Input
                    label="CVV"
                    value={cardCVV}
                    onChange={(event) => setCardCVV(event.target.value)}
                    maxLength={3}
                    containerProps={{ className: "min-w-[72px]" }}
                  />
                </div>
                <div className="flex flex-row gap-2 mb-4">
                  <Input
                    label="Telefon Numarası"
                    className="font-[Inter] "
                    maxLength={14}
                    value={formatPhoneNumber(cardHoldersPhone)}
                    containerProps={{ className: "min-w-[160px] w-[160px]" }} // Artırılan değer
                    onChange={(event) =>
                      setCardHoldersPhone(event.target.value)
                    }
                    icon={<PhoneIcon className="h-5 w-5 text-blue-gray-300" />}
                  />
                  <Input
                    label="Kart Sahibinin Adı"
                    className="font-[Inter] "
                    maxLength={22} // Set the maximum character limit to 22
                    value={cardHolder}
                    onChange={(event) => {
                      if (event.target.value.length <= 22) {
                        setCardHolder(event.target.value);
                      } else {
                        setCardHolder(event.target.value.slice(0, 22));
                      }
                    }}
                  />
                </div>
                <div className="flex flex-row gap-2 mb-4">
                  <Select
                    className="font-[Inter] "
                    onChange={(e) => handleChangeSchool(e)}
                    variant="outlined"
                    label="Ödeme türünü Seçiniz"
                  >
                    {schoolList.map((school, index) => (
                      <Option key={index} value={school.name}>
                        {school.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                {schoolName === "DİĞER" && (
                  <div className="flex flex-row gap-2">
                    <Input
                      className="font-[Inter] "
                      label="Ödeme detayı (örn: Gezi ücreti )"
                      value={specialMessage}
                      maxLength={22} // Set the maximum character limit to 22
                      containerProps={{ className: "min-w-[160px] w-[160px]" }}
                      onChange={(event) =>
                        setSpecialMessage(event.target.value)
                      }
                      icon={
                        <NewspaperIcon className="h-5 w-5 text-blue-gray-300" />
                      }
                    />
                  </div>
                )}
              </div>
              <Button className="font-[Inter] " onClick={handleOpenDialog} color="blue" size="lg">
                Ödemeyİ Tamamla
              </Button>
            </form>
          </CardBody>
        </Card>
        <Dialog open={openDialog} handler={handleOpenDialog}>
          <DialogHeader>Ödeme</DialogHeader>
          <DialogBody className="font-[Inter]">
            {cardHolder} isimli kişiye ait {cardNumber} numaralı karttan{" "}
            {schoolName} için {currencyAmount + "," + fractionAmount} ₺
            tutarındaki ödeme işlemi gerçekleştirilecektir. Onaylıyor musunuz?
          </DialogBody>
          <DialogFooter>
            <button
              className={
                "font-[Inter]  hover:bg-red-100/50 text-red-800 font-semibold px-6 py-2 rounded-md cursor-pointer"
              }
              onClick={handleOpenDialog}
            >
              <span>Hayır</span>
            </button>

            <button
              className={
                yesButtonState
                  ? "hover:bg-green-100/50 text-green-800 font-semibold px-6 py-2 rounded-md cursor-pointer"
                  : "hover:bg-gray-100/50 text-black font-semibold px-6 py-2 rounded-md cursor-not-allowed"
              }
              disabled={!yesButtonState}
              onClick={onClickSendButton}
            >
              <span>Evet</span>
            </button>
          </DialogFooter>
        </Dialog>
        <div className="flex flex-wrap justify-center gap-8 ">
          <img src={Troy} alt="Logo 1" className="h-4" />
          <img src={MasterCard} alt="Logo 2" className="h-4" />
          <img src={Visa} alt="Logo 3" className="h-4" />
          <img src={Secure} alt="Logo 4" className="h-4" />
        </div>
      </div>
    </div>
  );
}
