import React, { useState } from "react";
import SideBar from "./SideBar";
import SinglePayment from "./SinglePaymentPage";
import MultiplePayment from "./MultiplePaymentPage";
import PaymentLog from "./PaymentLog";
import SmsPage from "./SmsPage";

export default function Home({
  setLoggedIn,
  userType,
  userId,
  userName,
  userFirstName,
  userLastName,
}) {
  const [activePage, setActivePage] = useState("Geçmiş Ödeme İşlemleri");
  const handlePageChange = (page) => {
    setActivePage(page);
  };
  return (
    <div className="w-full h-full flex bg-blue-50 relative">
      <SideBar
        activePage={activePage}
        userId={userId}
        userFirstName={userFirstName}
        userLastName={userLastName}
        userType={userType}
        setLoggedIn={setLoggedIn}
        userName={userName}
        onButtonClick={handlePageChange}
      />
      {activePage === "Tekli Ödeme" && (
        <SinglePayment
          onButtonClick={handlePageChange}
          userId={userId}
          userType={userType}
        />
      )}
      {activePage === "Toplu Ödeme" && <MultiplePayment userId={userId} />}
      {activePage === "Sms Gönder" && <SmsPage userId={userId} />}
      {activePage === "Geçmiş Ödeme İşlemleri" && (
        <PaymentLog userId={userId} userType={userType} />
      )}
      {activePage === "Çıkış Yap" && (
        <div>{sessionStorage.setItem("loggedIn", false)}</div>
      )}
    </div>
  );
}
